 /* .body{
    margin-left: 15%;
    margin-right: 15%;
} */

 .container-privacy-policy {
     margin-top: 80px;
     padding-bottom: 15px;
 }

 .header-privacy-policy {
     text-align: center;

 }

 .content-privacy-policy {
     font-family: 'Poppins Regular';
     font-weight: 500;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.50;
     letter-spacing: normal;
     text-align: left;
 }

 .deletion-privacy-policy {
     font-family: 'Poppins Regular';
     font-weight: 400;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.73;
     letter-spacing: normal;
     text-align: left;

 }


 .dash {

     list-style-type: '-     ';
     font-family: 'Poppins Regular';
     font-weight: 400;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.73;
     letter-spacing: normal;
     text-align: left;
 }


 .underline {
     text-decoration: underline;
 }

 .font-weight-bold-privacy {
     font-weight: 600;
 }